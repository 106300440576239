<template>
  <div class="home">
    <div class="filter-wrapper">
      <div>
        <call-input
          v-model="dataSearch"
          type-input="search"
          placeholder="Search"
          @input="onSearch">
          <template slot="prefix">
            <font-awesome-icon
              :icon="dataIconSearch" />
          </template>
        </call-input>
        <call-checked-closed-opened v-model="dataFilterOpenedClosed" />
        <v-select
          v-if="!computedIsOperator"
          v-model="dataSelectTeamsValue"
          :options="dataSelectOptionsTeam"
          label="name"
          :components="{Deselect: dataComponentDeselect}"
          placeholder="All Teams"
          :class="['call-select', dataSelectTeamsValue ? 'call-select--value' : null]"
          @input="$_ajax({ reset: true })">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
        <v-select
          v-model="dataSelectLastDayValue"
          :options="dataSelectOptionsLastDay"
          :filterable="false"
          :searchable="false"
          :components="{Deselect: dataComponentDeselect}"
          placeholder="All"
          :class="['call-select', dataSelectLastDayValue ? 'call-select--value' : null]"
          @input="$_ajax({ reset: true })">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
        </v-select>
      </div>
      <div>
        <call-pagination
          :obj-pagination="dataObjPagination"
          @on-pagination="$_ajax" />
        <call-button
          v-if="computedIsAdmin || computedIsModerator"
          :icon="dataAddIcon"
          type="success"
          @click="clickAddProject()">
          Add Project
        </call-button>
      </div>
    </div>
    <div class="table-wrapper">
      <b-table
        striped
        hover
        no-local-sorting
        :fields="dataMixinUserFields"
        :items="dataProjectList"
        @sort-changed="onSortTabel"
        @row-clicked="(value) => onActions(value, 'view')"
        @row-hovered="onHover"
        @row-unhovered="onUnHover">
        <template #head(open_free_tasks_count)>
          <span class="warning">
            Open
          </span>
          Free
        </template>

        <template #head(open_assigned_tasks_count)>
          <span class="warning">
            Open
          </span>
          Assigned
        </template>

        <template #head(auto_closed_free_tasks_count)>
          <span>
            Closed
          </span>
          Free
        </template>

        <template #head(auto_closed_assigned_tasks_count)>
          <span class="success">
            Closed
          </span>
          Assigned
        </template>

        <template #head(manually_closed_tasks_count)>
          <span class="danger">
            Closed
          </span>
          Manually
        </template>

        <template #head(timeout_closed_tasks_count)>
          <span class="danger">
            Closed
          </span>
          Timeout
        </template>

        <template #cell(auto_closed_assigned_tasks_count)="data">
          <span class="success">{{ data.item.auto_closed_assigned_tasks_count }}</span>
        </template>
        <template #cell(manually_closed_tasks_count)="data">
          <span class="danger">{{ data.item.manually_closed_tasks_count }}</span>
        </template>
        <template #cell(timeout_closed_tasks_count)="data">
          <span class="danger">{{ data.item.timeout_closed_tasks_count }}</span>
        </template>
        <template #cell(name)="data">
          {{ data.item.name }}
          <b-badge
            v-if="data.item.vip"
            variant="vip">
            VIP
          </b-badge>
          <b-badge
            v-if="data.item.bonuses_available"
            variant="bonus">
            <font-awesome-icon
              class="tabel-icon"
              :icon="dataGiftIcon" />
          </b-badge>
        </template>

        <template #cell(isBlocked)="data">
          <font-awesome-icon
            v-if="!data.item.is_active"
            class="tabel-icon--red"
            :icon="dataIconLock" />
          <font-awesome-icon
            v-else
            class="tabel-icon--green"
            :icon="dataIconCheck" />
        </template>
        <template #cell(actions)="data">
          <call-table-project-actions
            v-show="data.item.actions"
            :data="data"
            :is-admin="computedIsAdmin"
            :blocked="(data.item.is_active && (computedIsAdmin || computedIsModerator))"
            @on-action="(value) => onActions(data.item, value)" />
        </template>
      </b-table>
    </div>
    <call-modal-add-project
      v-model="dataShowAddProject"
      @on-update="$_ajax" />
    <call-modal-edit-project
      v-model="dataShowEditProject"
      :project-data="dataCurrentRow"
      @on-update="$_ajax" />
    <call-deactive-project
      v-model="dataShowDeactivateProject"
      :project-id="dataDeactivateProjectIdProject"
      @on-update="$_ajax" />
  </div>
</template>

<script>
import { BBadge, BTable } from 'bootstrap-vue';
import { faCheck, faChevronLeft, faChevronRight, faPlus, faPowerOff, faSearch, faGift } from '@fortawesome/free-solid-svg-icons';
import CallInput from '../../../components/common/CallInput';
import { mapGetters } from 'vuex';
import { PROJECTS_GET_ALL, TEAMS_GET_ALL } from '../../../store/types/actions.types';
import debounce from 'lodash/debounce';
import selectAllTeams from '../../../mixins/select/selectAllTeams';
import projectTable from '../../../mixins/table/projectTable';
import CallPagination from '../../../components/desktop/CallPagination';
import CallTableProjectActions from '../../../components/desktop/CallTableProjectActions';
import helper, { tabelCell } from '../../../service/helper';
import CallButton from '../../../components/common/CallButton';
import CallModalAddProject from '../../../components/common/modals/CallAddProject';
import CallModalEditProject from '../../../components/common/modals/CallEditProject';
import CallDeactiveProject from '../../../components/common/modals/CallDeactiveProject';
import selectProjectPeriod from '@/mixins/select/selectProjectPeriod';
import CallCheckedClosedOpened from '../../../components/common/CallCheckedClosedOpened';

export default {
  name: 'PageProjects',
  components: {
    CallDeactiveProject,
    CallModalAddProject,
    CallModalEditProject,
    CallButton,
    CallTableProjectActions,
    CallPagination,
    CallInput,
    BTable,
    BBadge,
    CallCheckedClosedOpened
  },
  mixins: [projectTable, selectAllTeams, selectProjectPeriod],
  data () {
    return {
      dataGiftIcon: faGift,
      dataAddIcon: faPlus,
      dataIconSearch: faSearch,
      dataIconCheck: faCheck,
      dataIconLock: faPowerOff,
      dataRowLeft: faChevronLeft,
      dataRowRight: faChevronRight,
      dataShowAddProject: false,
      dataShowEditProject: false,
      dataShowDeactivateProject: false,
      dataDeactivateProjectIdProject: null,
      dataProjectList: [],
      dataSearch: '',
      dataDesc: null,
      dataOrderBy: null,
      dataObjPagination: {},
      dataFilterOpenedClosed: 'opened',
      dataCurrentRow: {}
    };
  },
  computed: {
    ...mapGetters({
      computedIsAdmin: 'auth/isAdmin',
      computedIsModerator: 'auth/isModerator',
      computedIsOperator: 'auth/isOperator'
    }),
    computedDataFilterOpenedClosed () {
      if (this.dataFilterOpenedClosed === 'closed') {
        return false;
      }
      if (this.dataFilterOpenedClosed === 'opened') {
        return true;
      }
      return undefined;
    }
  },

  watch: {
    dataFilterOpenedClosed (v) {
      this.$_ajax({ reset: true });
    }
  },

  async created () {
    this.$_ajax = ((options = {}) => {
      let _page = 0;
      let _limit = tabelCell();

      const { currentRoute: { name: pageName } = {} } = this.$router;

      const _filterKey = `_filter${pageName}`;

      if (!helper.isEmpty(this.$route.query, _filterKey)) {
        try {
          options = helper.clearObject(JSON.parse(this.$route.query[_filterKey]));

          const _arrFilter = [
            'search',
            'teamId',
            'desc',
            'orderBy',
            'limit',
            'page',
            'period',
            'is_active'
          ];

          for (const _key of _arrFilter) {
            if (!helper.isEmpty(options, _key)) {
              switch (_key) {
                case 'search':
                  this.dataSearch = options[_key];
                  break;
                case 'teamId':
                  this.dataSelectTeamsValue = options[_key];
                  break;
                case 'desc':
                  this.dataDesc = options[_key];
                  break;
                case 'orderBy':
                  this.dataOrderBy = options[_key];
                  break;
                case 'limit':
                  _limit = options[_key];
                  break;
                case 'page':
                  _page = options[_key];
                  break;
                case 'period':
                  this.dataSelectLastDayValue = options[_key];
                  break;
                case 'is_active':
                  this.dataFilterOpenedClosed = options[_key];
                  break;
              }
            }
          }
        } catch (e) {
          console.log('Error query params');
        }
      }

      return async ({ search = this.dataSearch, teamId = this.dataSelectTeamsValue, desc = this.dataDesc, orderBy = this.dataOrderBy, limit = null, page = null, reset = false } = options) => {
        if (!helper.isEmpty(page) && page !== _page) {
          _page = page;
        }

        if (!helper.isEmpty(limit) && limit !== _limit) {
          _limit = limit;
        }

        if (reset) {
          _page = 0;
        }

        if (!this.dataSelectLastDayValue) {
          this.dataSelectLastDayValue = { ...this.dataSelectLastDayValueDefault };
        }

        const { payload: { list = [] }, misc: { paging = {} } } = await this.$store.dispatch(`project/${PROJECTS_GET_ALL}`, {
          params: helper.clearObject({
            search,
            team_ids: [teamId?.id],
            desc,
            order_by: orderBy,
            limit: _limit,
            page: _page,
            period: this.dataSelectLastDayValue?.key === -1 ? null : this.dataSelectLastDayValue?.key,
            is_active: this.computedDataFilterOpenedClosed
          })
        });
        this.dataObjPagination = paging;
        this.dataProjectList = list.map((item) => {
          item.actions = false;
          return item;
        });

        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            [_filterKey]: JSON.stringify(helper.clearObject({
              search,
              teamId,
              desc,
              orderBy,
              limit: _limit,
              page: _page,
              period: this.dataSelectLastDayValue?.key === -1 ? null : this.dataSelectLastDayValue,
              is_active: this.dataFilterOpenedClosed
            }))
          }
        }).catch(() => {});

        options = {};
      };
    })();

    const [{ payload: { list: listTeam = [] } }] = await Promise.all([
      this.$store.dispatch(`team/${TEAMS_GET_ALL}`, {
        params: {
          short: true
        }
      }),
      this.$_ajax()
    ]);
    this.dataSelectOptionsTeam = listTeam;
  },
  methods: {
    clickAddProject () {
      this.dataShowAddProject = true;
    },
    clickEditProject (value) {
      this.dataCurrentRow = value;
      this.dataShowEditProject = true;
    },
    onSortTabel ({ sortBy, sortDesc }) {
      this.dataDesc = sortDesc;
      this.dataOrderBy = sortBy;
      this.$_ajax();
    },
    onSearch: debounce(function () {
      this.$_ajax({ reset: true });
    }, 1200),
    async  onActions ({ id: projectId, is_active: isBlocked, name = '', bonuses_available, vip }, action) {
      switch (action) {
        case 'update':
          this.clickEditProject({ bonuses_available, vip, id: projectId });
          break;
        case 'view':
          this.$router.push({
            name: 'ProjectTasks',
            params: {
              projectId
            },
            query: {
              ...(this.$route.query || {}),
              projectName: name
            }
          });
          break;
        case 'blocked':
          this.dataShowDeactivateProject = true;
          this.dataDeactivateProjectIdProject = projectId;
          break;
      }
    }
  }
};
</script>
